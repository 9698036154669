import React, { useEffect } from "react";

import Layout from "../../components/Layout";

export default function Thanks() {
  useEffect(() => {
    if (window.analytics) {
      window.analytics.track("Lead");
      console.log("Event sent");
    }
  }, []);

  return (
    <Layout>
      <section className="hero is-light is-bold is-halfheight">
        <div class="hero-body">
          <div className="container">
            <div className="content">
              <h1 className="title is-1 has-text-centered is-spaced">
                Thank you!
              </h1>
              <h2 className="subtitle is-3 has-text-centered">
                Our representative will call you at the selected time.
              </h2>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
